import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UserSession {
  name: string
  cpf: string
}

interface UserStore {
  user: UserSession | null
  setUser: (user: UserSession) => void
  clearUser: () => void
}

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      user: null,
      setUser: (user) => set({ user }),
      clearUser: () => set({ user: null }),
    }),
    {
      name: 'user-storage',
    }
  )
) 