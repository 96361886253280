import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DetailsDrawerProps } from "./types";
import { Carousel } from "react-responsive-carousel";
import {
  FaBookmark,
  FaForwardStep,
  FaHandHoldingHeart,
  FaPix,
  FaSackDollar,
} from "react-icons/fa6";
import { QRCodeSVG } from "qrcode.react";
import { MdCheck, MdContentCopy } from "react-icons/md";
import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useForm } from "react-hook-form";
import Markdown from "markdown-to-jsx";
import CurrencyInput from "react-currency-input-field";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { FaRegUser, FaUserAlt } from "react-icons/fa";
import { useUserStore } from '../../stores/useUserStore';

interface DonationForm {
  name: string;
  cpf: string;
  value: number;
}

export default function DetailsDrawerHome({
  org = false,
  item,
  isOpen,
  onClose,
}: DetailsDrawerProps) {
  const [copied, setCopied] = useState(false);
  const [showDonationForm, setShowDonationForm] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [step, setStep] = useState(1);
  
  // Substituir o userSession pelo store do Zustand
  const { user, setUser, clearUser } = useUserStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DonationForm>();

  const onSubmit = async (data: DonationForm) => {
    try {
      console.log(data);
      
      await axios.post("https://app.pontedobem.org/donations/create", {
        ...data,
        name: user?.name,
        cpf: user?.cpf,
        institutionId: org ? item?.id : undefined,
        campaignId: !org ? item?.id : undefined,
      });

      setShowQRCode(true);
    } catch (error) {
      console.error("Erro ao registrar doação:", error);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // Função para salvar dados da primeira etapa
  const handleFirstStep = (data: { name: string; cpf: string }) => {
    setUser(data);
    setStep(2);
  };

  const handleRemoveSession = () => {
    clearUser();
    setStep(1);
  };

  if (!item) return null;

  return (
    <Drawer
      isOpen={isOpen}
      size={"xl"}
      placement="right"
      onClose={() => {
        onClose();
        setShowDonationForm(false);
        setShowQRCode(false);
        setCopied(false);
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader fontSize={32} fontWeight="bold">
          {item.name}
        </DrawerHeader>

        <DrawerBody
          position="relative"
          w="100%"
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "gray.200",
              borderRadius: "24px",
            },
          }}
        >
          <Flex
            p={2}
            w="100%"
            justifyContent={"center"}
            alignItems={"center"}
            position={"relative"}
          >
            <Carousel
              width={"100%"}
              stopOnHover
              showStatus={false}
              autoPlay
              centerMode
              swipeable={true}
              emulateTouch={true}
              infiniteLoop={true}
              centerSlidePercentage={90}
              // @ts-ignore
              renderThumbs={() => null}
              useKeyboardArrows
              interval={6000}
              transitionTime={500}
            >
              {item.images?.map((image) => {
                return (
                  <Stack ml={3} key={image.id}>
                    <Image
                      maxH={450}
                      objectFit={"cover"}
                      src={image.url}
                      style={{ borderRadius: 24, marginLeft: 10 }}
                      alt={image.key}
                    />
                  </Stack>
                );
              })}
            </Carousel>
          </Flex>

          <Box px={6} py={10}>
            {/* <Text fontSize={"lg"} whiteSpace="pre-line" lineHeight="1.8">
              {item.description}
            </Text> */}
            <Text fontSize={"lg"} whiteSpace="pre-line" lineHeight="1.8">
              <Markdown>{item.description}</Markdown>
            </Text>
            {/* <ReactMarkdown children={item.description} />; */}
          </Box>

          <VStack pb={36} spacing={6} width="100%" alignItems="center">
            {!user && !showDonationForm && !showQRCode && (
              <>
                <Box
                  position="fixed"
                  bottom={0}
                  pb={6}
                  width="calc(100%)" // 48px é para compensar o padding do drawer
                  maxW="calc(var(--chakra-sizes-4xl))"
                  paddingX={12}
                  bg="white"
                >
                  <Button
                    w="100%"
                    size="lg"
                    height="70px"
                    bg="green.500"
                    color="white"
                    fontSize="24px"
                    fontWeight="bold"
                    onClick={() => setShowDonationForm(true)}
                    _hover={{
                      bg: "green.600",
                      transform: "translateY(-2px)",
                      boxShadow: "xl",
                    }}
                    transition="all 0.2s"
                    boxShadow="0 -15px 20px rgba(0, 0, 0, 0.1)"
                  >
                    Quero apoiar!
                  </Button>
                  <Box
                    position="absolute"
                    top="-100px"
                    left={0}
                    right={0}
                    height="100px"
                    bgGradient="linear(to-t, white, transparent)"
                    pointerEvents="none"
                  />
                </Box>
              </>
            )}

            <Divider mb={6} />
            {(showDonationForm || !!user) && !showQRCode && (
              <Box width="100%" maxWidth="500px">
                {step === 1 && !user ? (
                  // Primeira etapa - Dados pessoais
                  <Box as="form" onSubmit={handleSubmit(handleFirstStep)}>
                    <HStack alignItems={"start"}>
                      <Icon
                        pt={2}
                        as={FaRegUser}
                        fontSize={36}
                        color="brand.green"
                      />
                      <VStack align="start" spacing={1}>
                        <Text
                          fontSize={"lg"}
                          fontWeight={700}
                          color="brand.green"
                        >
                          Quem está doando?
                        </Text>
                        <Text fontSize={"sm"} color="gray.500">
                          Precisamos de algumas informações para concluir a
                          doação
                        </Text>
                      </VStack>
                    </HStack>
                    <VStack spacing={4} mt={4}>
                      <Box width="100%">
                        <Input
                          placeholder="Nome completo"
                          {...register("name", {
                            required: "Nome é obrigatório",
                          })}
                          w="100%"
                          borderWidth={2}
                          borderColor={"brand.green"}
                          _placeholder={{
                            color: "gray.400",
                            fontWeight: 700,
                          }}
                        />
                      </Box>
                      <Box width="100%">
                        <Input
                          placeholder="CPF"
                          {...register("cpf", {
                            required: "CPF é obrigatório",
                          })}
                          w="100%"
                          borderWidth={2}
                          borderColor={"brand.green"}
                        />
                      </Box>
                      <Button
                        type="submit"
                        width="100%"
                        bgColor={"brand.green"}
                      >
                        Continuar
                      </Button>
                    </VStack>
                  </Box>
                ) : (
                  // Segunda etapa - Valor da doação
                  <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                    <HStack alignItems={"start"}>
                      <Icon
                        pt={2}
                        as={FaUserAlt}
                        fontSize={36}
                        color="brand.green"
                      />
                      <VStack align="start" spacing={1}>
                        <Text
                          fontSize={"lg"}
                          fontWeight={700}
                          color="brand.green"
                          css={{
                            textTransform: "capitalize",
                          }}
                        >
                          {user?.name}
                        </Text>
                        <Text fontSize={"sm"} color="gray.500">
                          CPF: {user?.cpf}
                        </Text>
                      </VStack>
                    </HStack>
                    <Button
                      mt={4}
                      variant="link"
                      color="brand.green"
                      fontWeight={400}
                      textDecoration={"underline"}
                      onClick={() => {
                        handleRemoveSession();
                        setStep(1);
                      }}
                    >
                      Editar dados do doador
                    </Button>
                    <Text fontSize={"md"} textAlign="left" py={5}>
                      Defina o valor que deseja doar para <b>{item.name}</b>
                    </Text>
                    <VStack spacing={4}>
                      <Box width="100%">
                        <Input
                          as={CurrencyInput}
                          decimalsLimit={2}
                          prefix="R$ "
                          groupSeparator="."
                          decimalSeparator=","
                          placeholder="Valor da doação"
                          {...register("value", {
                            required: "Você deve adicionar o valor da doação",
                          })}
                          w="100%"
                          borderWidth={2}
                          borderColor={"brand.green"}
                        />
                      </Box>
                      <Button
                        type="submit"
                        width="100%"
                        bgColor={"brand.green"}
                      >
                        Confirmar Doação
                      </Button>
                      <Text fontSize={"xs"}>
                        Toda doação é enviada diretamente à campanha ou
                        instituição, não possuímos nenhum controle do valor
                        contribuído.
                      </Text>
                    </VStack>
                  </Box>
                )}
              </Box>
            )}
            {showQRCode && (
              <>
                <HStack alignItems={"start"}>
                  <Text fontSize={"lg"} fontWeight={700} color="brand.green">
                    Muito obrigado, sua ajuda faz toda diferença!
                  </Text>
                </HStack>

                <Text fontSize={"lg"} py={4} w="60%" textAlign={"center"}>
                  Leia o QR Code abaixo para contribuir com o valor informado
                  para <b>{item.name}</b>.
                </Text>
                <Box
                  bg="#98BA8055"
                  width={"fit-content"}
                  padding={5}
                  rounded={"xl"}
                >
                  <QRCodeSVG
                    size={200}
                    value={item.pixQRCodeRaw}
                    bgColor="#98BA8055"
                  />
                </Box>
                <Button
                  mt={4}
                  variant="ghost"
                  leftIcon={copied ? <MdCheck /> : <MdContentCopy />}
                  onClick={() => copyToClipboard(item.pixQRCodeRaw)}
                >
                  {copied
                    ? "Seu código foi copiado com sucesso!"
                    : "Clique aqui para copiar o código pix"}
                </Button>
              </>
            )}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
